import { useState, useEffect } from "react";
import useAuth from "../../../../hooks/useAuth";
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import MainWrapper from "../../layouts/MainWrapper";
import CardTotal from "./components/CardTotal";
import { useTranslation } from "react-i18next";
import Utils from "../../../../utils";
import ButtonU from './../../../../components/ui/ButtonU'
import icProduct from '../../../../assets/svg/ic_cube.svg';
import icUsers from '../../../../assets/svg/ic_users.svg';
import icCartShopping from '../../../../assets/svg/ic_cart_shopping.svg';
import icReceipt from '../../../../assets/svg/ic_receipt.svg';
import SaleRadialBarChart from "./charts/SaleRadialBarChart";
import SaleAreaChart from "./charts/SaleAreaChart";
import { Link, useNavigate } from "react-router-dom";
import { faAdd, faList } from "@fortawesome/free-solid-svg-icons";

const Dashboard = () => {
    const { t } = useTranslation();
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const [countProducts, setCountProducts] = useState();
    const [countSuppliers, setCountSuppliers] = useState();
    const [countSales, setCountSales] = useState();
    const [countInvoices, setCountInvoices] = useState();
    const [avgDailySales, setAvgDailySales] = useState();
    const [sumTotalSale, setSumTotalSale] = useState();
    const [saleTotals, setSaleTotals] = useState({});
    
    const today = new Date(Utils.getCurrentDateISO()).toISOString().split('T')[0];

    const currentDate = new Date();
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).toLocaleDateString('en-CA');
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).toLocaleDateString('en-CA');
       
    const breadcrumbs = [ { title: t('MAIN'), active: true } ];
    
    useEffect(() => {
        if (ready) {
            getCountProducts();
            getCountSuppliers();
            getCountSales();
            getCountInvoices();
            getAvgDailySales();
            getSumTotalSale();
            getSaleTotals();
        }
    }, [ready]);

    const getCountProducts = () => {
        axiosPrivate.get('/count/products').then((res) => {
            if (res.status == 200) setCountProducts(res.data);
        });
    }

    const getCountSuppliers = () => {
        axiosPrivate.get('/count/suppliers').then((res) => {
            if (res.status == 200) setCountSuppliers(res.data);
        });
    }

    const getCountSales = () => {
        const query = { filter: { createdAtLb: today }};
        axiosPrivate.get('/count/sales', { params: query }).then((res) => {
            if (res.status == 200) setCountSales(res.data);
        });
    }

    const getCountInvoices = () => {
        const query = { filter: { createdAtLb: today }};
        axiosPrivate.get('/count/invoices', { params: query }).then((res) => {
            if (res.status == 200) setCountInvoices(res.data);
        });
    }

    const getAvgDailySales = () => {
        const query = { filter: { createdAtLb: firstDayOfMonth, createdAtUb: lastDayOfMonth }};
        axiosPrivate.get('/reports/avg-daily-sales', { params: query }).then((res) => {
            if (res.status == 200) setAvgDailySales(res.data);
        });
    }

    const getSumTotalSale = () => {
        const query = { filter: { createdAtLb: today }};
        axiosPrivate.get('/reports/sum-total-sale', { params: query }).then((res) => {
            if (res.status == 200) setSumTotalSale(res.data);
        });
    }

    const getSaleTotals = () => {
        const query = { filter: { createdAtLb: firstDayOfMonth, createdAtUb: lastDayOfMonth }};
        axiosPrivate.get('/reports/sale-totals', { params: query }).then((res) => {
            if (res.status == 200) setSaleTotals(res.data);
        });
    }

    return (
        <MainWrapper loading={loading} breadcrumbs={breadcrumbs}>
            <div className="main-page flex-column mx-5 px-5 py-2">
                <div className="d-flex flex-row align-items-center justify-content-between mx-3 mt-4">
                    <span className="fs-4 medium fg-dark">{t('DASHBOARD')}</span>
                </div>
                <div className="row mx-0 mt-3">
                    <CardTotal title={t('PRODUCTS')} value={countProducts} icon={icProduct} bg={'bg-yellow-200'} link={'/product/products'} leftTitle={t('TOTAL')}/>
                    <CardTotal title={t('SUPPLIERS')} value={countSuppliers} icon={icUsers} bg={'bg-purple-100'} link={'/company/suppliers'} leftTitle={t('TOTAL')}/>
                    <CardTotal title={t('SALES')} value={countSales} icon={icCartShopping} bg={'bg-blue-100'} link={'/finance/sales'} leftTitle={t('TODAY')}/>
                    <CardTotal title={t('INVOICES')} value={countInvoices} icon={icReceipt} bg={'bg-orange-100'} link={'/invoice/invoices'} leftTitle={t('TODAY')}/>
                </div>
                <div className="d-flex flex-column mx-3 mt-5">
                    <span className="fg-dark medium font-18">{t('SHORTCUTS')}</span>
                    <div className="d-flex flex-row mt-2">
                        <ButtonU onSubmit={() => navigate(`/invoice/invoices/create`)} className={'bg-orange-400 text-white'} icon={faAdd} text={t('INVOICE')}/>
                        <ButtonU onSubmit={() => navigate('/product/products/create')} className={'bg-blue-500 text-white'} icon={faAdd} text={t('PRODUCT')}/>
                        <ButtonU onSubmit={() => navigate('/company/suppliers')} className={'bg-purple-400 text-white'} icon={faAdd} text={t('SUPPLIER')}/>
                        <ButtonU onSubmit={() => navigate(`/company/expenses`)} className={'fg-blue-500 border-primary'} icon={faList} text={t('EXPENSES')}/>
                    </div>
                </div>
                <div className="d-flex flex-row font-18 mx-3 mt-5">
                    <span className="fg-dark medium">{t('STATISTICS')}</span>
                    <Link to={'/finance/sales'} className="fg-blue-300 ms-2">
                        {t('SALES')}
                    </Link>
                </div>
                <div className="row mx-0 mt-2">
                    <SaleRadialBarChart sumTotalSale={sumTotalSale} avgDailySales={avgDailySales}/>
                    <SaleAreaChart seriesTotals={saleTotals?.totals} seriesDates={saleTotals?.dates}/>
                </div>
            </div>
        </MainWrapper>
    );
}

export default Dashboard;