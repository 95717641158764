import { useEffect, useRef, useState } from 'react';
import useAuth from "../../../../../../hooks/useAuth";
import AdminVerification from "../../../../../../components/ui/AdminVerification";
import { useNavigate } from "react-router-dom";
import ModalU from '../../../../../../components/ui/ModalU';
import Keys from "../../../../../../utils/constants";
import ToastU from "../../../../../../components/ui/ToastU";
import { useTranslation } from "react-i18next";
import ButtonU from '../../../../../../components/ui/ButtonU';
import InvisibleInput from '../../../../components/ui/InvisibleInput';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const Discard = ({...props}) => {
    const { t } = useTranslation();
    const { setTempUnlocked } = useAuth();
    const [adminVerificationModal, setAdminVerificationModal] = useState(false);
    const navigate = useNavigate();
    const enterRef = useRef();

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    useEffect(() => {
        enterRef?.current?.focus();
    }, [props.modalShow]);

    const handleSubmit = (event) => {
        event.preventDefault();
        setAdminVerificationModal(true);
    }

    const setOnSuccess = () => {
        setTempUnlocked(false);
        props.setModalShow(false);
        navigate(-1);
    }

    return (
        <ModalU show={props.modalShow} size={''} title={t('WARNING')} onHide={props.setModalShow}>
                
            <form className="d-flex flex-column align-items-center p-3" onSubmit={handleSubmit}>
                <span className="medium fg-red-300 font-20">{t('DISCARD_SALE_ITEM_RETURN')}</span>
                <InvisibleInput enterRef={enterRef}/>
                <ButtonU type={'submit'} onSubmit={handleSubmit} icon={faCheck} text={t('OK')} className={"bg-red-200 border-danger fg-red-500 bg-gradient px-5"}/>
            </form>
            <ToastU toast={toast} setToast={setToast}/>
            <AdminVerification
                show={adminVerificationModal}
                onHide={setAdminVerificationModal}
                appName={Keys.CP}
                setOnSuccess={setOnSuccess}
                toast={toast}
                setToast={setToast}
            />
        </ModalU>
    );
}

export default Discard