import { Link, useLocation } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Keys from "../../../utils/constants";
import { faHome, faTableColumns } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

const NavBar = () => {
    const location = useLocation();
    const { t } = useTranslation();
   
    const items = [
        { name: t('MAIN'), id: "main", icon: faHome, links: Keys.CASHIER_MAIN_NAV_BAR },
        { name: t('ADMIN_PANEL'), id: "main", icon: faTableColumns, links: Keys.CASHIER_ADMIN_NAV_BAR },
    ];


    return (
        <ul className="cp-nav-bar" id="cp-nav-bar">
            { items.map((item)=>{
                return (
                    <Link to={item.links[0]} className={"nav-item"} key={item.id}>
                        <div className={"nav-link " + (!item.links.includes(location.pathname) && 'collapsed')} data-bs-target={`#${item.id}`} data-bs-toggle="collapse">
                            <FontAwesomeIcon icon={item.icon} className="icon" />
                            <span>{item.name}</span>
                        </div>
                    </Link>
                );
            }) }
        </ul>
    );
}

export default NavBar;