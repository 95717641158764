import { useEffect, useState } from "react";
import useAuth from "../../../../../hooks/useAuth";
import useAxiosPrivate from '../../../../../hooks/useAxiosPrivate';
import ModalU from '../../../../../components/ui/ModalU';
import Utils from "../../../../../utils";
import InputLabeled from '../../../../../components/form/InputLabeled';
import SelectLabeled from '../../../../../components/form/SelectLabeled';
import { useTranslation } from "react-i18next";

const Update = ({...props}) => {
    const { t } = useTranslation();
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const item = props.item;
    
    const [name, setName] = useState('');
    const [provinceId, setProvinceId] = useState('');
    const [districtId, setDistrictId] = useState('');
    const [addressLine, setAddressLine] = useState('');
    const [postalCode, setPostalCode] = useState();
    const [phoneNumber, setPhoneNumber] = useState('');

    const [provinces, setProvinces] = useState([]);
    const [districts, setDistricts] = useState([]);

    useEffect(() => {
        setName(item.name);
        setProvinceId(item.provinceId);
        setDistrictId(item.districtId);
        setAddressLine(item.addressLine);
        setPostalCode(item.postalCode);
        setPhoneNumber(item.phoneNumber);
    }, [item])

    useEffect(() => {
        if (ready) {
            getProvinces();
        }
    }, [ready]);

    useEffect(() => {
        getDistricts();
    }, [provinceId])

    const getProvinces = () => {
        props.setLoading(true);
        const query = { order: [['name', 'ASC']] }; 
        axiosPrivate.get('/provinces', { params: query }).then((res) => {
            if (res.status == 200) setProvinces(res.data.data);
        }).finally(() => {props.setLoading(false)});
    }

    const getDistricts = () => {
        if (!provinceId) return;
        
        props.setLoading(true);
        const query = { order: [['name', 'ASC']], filter: { provinceId } };
        axiosPrivate.get('/districts', { params: query }).then((res) => {
            if (res.status == 200) setDistricts(res.data.data);
        }).finally(() => {props.setLoading(false)});
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        
        const requiredInputs = [
            { key: name, value: t('NAME_REQUIRED') },
            { key: provinceId, value: t('PROVINCE_REQUIRED') },
            { key: districtId, value: t('DISTRICT_REQUIRED') },
            { key: phoneNumber, value: t('PHONE_NUMBER_REQUIRED') },
        ]

        const content = Utils.errorHandling(requiredInputs);
        if (content) {
            props.setToast({ content, variant: 'danger', title: t('ERROR'), show: true });
            return;
        }

        props.setLoading(true);
        const data = { name, provinceId, districtId, addressLine, postalCode, phoneNumber };
        axiosPrivate.put('/branches/update/'+item.id, data).then((res) => {
            if (res.status == 200) {
                props.setModalShow(false);
                props.setToast({ content: t('SUCCESS'), variant: 'success', title: t('SUCCESS'), show: true });
            }
        }).catch((err) => {
            if (!err?.response) props.setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
            else props.setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true });
        }).finally(() => {props.setLoading(false)});
    }

    return (
        <ModalU show={props.modalShow} size={''} title={`${t('PROVINCE')} [${t('EDIT')}]`}
            actionbtntext={t('UPDATE')}
            actionbtnclassname={"bg-green-700 font-14 medium text-white"}
            onSubmit={handleSubmit} onHide={props.setModalShow}>
                
            <form onSubmit={handleSubmit}>
                <InputLabeled label={t('NAME')} type={'text'} value={name} setValue={setName} hint={t('NAME')} required={true}/>
                <div className="d-flex row">
                    <div className="col-md-6">
                        <SelectLabeled label={t('PROVINCE')} items={provinces} value={provinceId} setValue={setProvinceId} required={true}/>
                    </div>
                    <div className="col-md-6">
                        <SelectLabeled label={t('DISTRICT')} items={districts} value={districtId} setValue={setDistrictId} required={true}/>
                    </div>
                </div>
                <div className="d-flex row">
                    <div className="col-md-6">
                        <InputLabeled label={t('PHONE_NUMBER')} type={'number'} value={phoneNumber} setValue={setPhoneNumber} hint={t('HINT_PHONE_NUMBER')} required={true}/>
                    </div>
                    <div className="col-md-6">
                        <InputLabeled label={t('POSTAL_CODE')} type={'number'} value={postalCode} setValue={setPostalCode} hint={t('HINT_POSTAL_CODE')}/>
                    </div>
                </div>
                <InputLabeled label={t('ADDRESS')} type={'text'} value={addressLine} setValue={setAddressLine} hint={t('HINT_ADDRESS')}/>
            </form>
        </ModalU>
    );
}

export default Update