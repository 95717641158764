import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import useAuth from "../../../../../hooks/useAuth";
import useAxiosPrivate from '../../../../../hooks/useAxiosPrivate';
import { faAdd, faSearch, faArrowRotateRight } from "@fortawesome/free-solid-svg-icons";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import CompanyWrapper from "../CompanyWrapper";
import InputField from "../../../../../components/form/InputField";
import TableStd from '../../../../../components/ui/TableStd';
import Filter from "./Filter";
import PaginationU from '../../../../../components/ui/PaginationU';
import LimitU from '../../../../../components/ui/LimitU';
import DataNotFound from '../../../../../components/ui/DataNotFound';
import ButtonU from "../../../../../components/ui/ButtonU";
import ToastU from "../../../../../components/ui/ToastU";
import SortU from "../../../../../components/ui/SortU";
import Create from "./Create";
import Update from "./Update";
import Delete from "./Delete";
import { useTranslation } from "react-i18next";
import Keys from "../../../../../utils/constants";
import Actions from "../../../../../components/ui/Actions";

const Branches = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const [loading, setLoading] = useState(false);

    const [branches, setBranches] = useState([]);
    const [branchesCount, setBranchesCount] = useState();

    const parsed = queryString.parse(location.search);
    const urlQuery = {
        page: parsed.page || 1,
        limit: 10,
        order: parsed.order || [['name', 'ASC']],
        filter: { createdAtLb: '', createdAtUb: '' }
    }
    const [query, setQuery] = useState(urlQuery);

    const [find, setfind] = useState('');

    const [modalCreate, setModalCreate] = useState(false);
    const [modalUpdate, setModalUpdate] = useState(false);
    const [modalDelete, setModalDelete] = useState(false);
    
    const [itemToBe, setItemToBe] = useState({});

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    const breadcrumbs = [
        { title: t('MAIN'), link: '/' },
        { title: t('COMPANY'), link: '/company/branches' } ,
        { title: t('BRANCHES'), active: true}
    ]

    useEffect(() => {
        if (ready) {
            getUpdatedUrl();
            getBranches();
        }
    }, [ready, query, toast]);

    const getUpdatedUrl = () => {
        parsed.page = query.page;
        window.history.replaceState({}, '', `${location.pathname}?${queryString.stringify(parsed)}`);
    }
 
    const getBranches = () => {
        setLoading(true);
        axiosPrivate.get('/branches', { params: query }).then((res) => {
            if (res.status == 200) {
                setBranches(res.data.data);
                setBranchesCount(res.data.count);
            }
        }).finally(() => {setLoading(false)});
    }
    
    useEffect(() => {
        setQuery({...query, filter: { name: find }});
    }, [find]);

    const createNew = () => { setModalCreate(true); }

    const refresh = () => { getBranches() }
   
    const modelProps = [
        { 
            name: <SortU title={t('NAME')} attr={'name'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop.name}</>) } 
        },
        {
            name: t('PROVINCE'),
            value: (prop) => { return(<>{prop.province.name}</>) } 
        },
        {
            name: t('DISTRICT'),
            value: (prop) => { return(<>{prop.district.name}</>) } 
        },
        {
            name: t('ADDRESS'),
            value: (prop) => { return(<>{prop.addressLine}</>) } 
        },
        {
            name: t('POSTAL_CODE'),
            value: (prop) => { return(<>{prop.postalCode}</>) } 
        },
        {
            name: t('PHONE_NUMBER'),
            value: (prop) => { return(<>{Keys.PHONE_CODE} {prop.phoneNumber}</>) } 
        },
        {
            name: t('ACTION'),
            value: (prop) => {
                const options =[
                    {
                        icon: faPenToSquare,
                        style: "fg-blue-500 font-14",
                        onClick: () => {
                            setItemToBe(prop);
                            setModalUpdate(true);
                        }
                    },
                    {
                        icon: faTrashCan,
                        style: "fg-red-300 font-14",
                        onClick: () => { 
                            setItemToBe(prop);
                            setModalDelete(true);
                        }
                    }
                ]
                return (<Actions items={options}/>)
            }
        }
    ];

    return (
        <CompanyWrapper loading={loading} breadcrumbs={breadcrumbs}>
            <div className="head-long-page">
                <span className="fs-5 bold fg-dark">{t('BRANCHES')}</span>
                <div className="d-flex align-items-center">
                    <ButtonU onSubmit={refresh} className={'border-dark fg-dark'} icon={faArrowRotateRight}/>
                    <InputField type={"text"} value={find} setValue={setfind} hint={t('FIND')} icon={faSearch} formClass="input-field-container me-1 font-14" inputClass="input-field-outline"/> 
                    <Filter query={query} setQuery={setQuery} />
                    <ButtonU onSubmit={createNew} className={'border-success fg-green-500'} icon={faAdd} text={t('ADD')}/>
                </div>
            </div>
            { branchesCount ?
                <div className="mt-5">
                    <TableStd modelProps={modelProps} data={branches} count={branchesCount} query={query}/>
                    <div className='d-flex align-items-center justify-content-between'>
                        <LimitU size={"sm"} filter={query} setFilter={setQuery}/>
                        <PaginationU filter={query} setFilter={setQuery} count={branchesCount} />
                    </div>
                </div>
                : <DataNotFound/>
            }
            <Create
                modalShow={modalCreate}
                setModalShow={setModalCreate}
                toast={toast}
                setToast={setToast}
                setLoading={setLoading}
            />
            <Update
                modalShow={modalUpdate}
                setModalShow={setModalUpdate}
                toast={toast}
                setToast={setToast}
                item={itemToBe}
                setLoading={setLoading}
            />
            <Delete
                modalShow={modalDelete}
                setModalShow={setModalDelete}
                toast={toast}
                setToast={setToast}
                item={itemToBe}
                setLoading={setLoading}
            />
            <div className="mb-5"></div>
            <ToastU toast={toast} setToast={setToast}/>
        </CompanyWrapper>
    );
}

export default Branches;