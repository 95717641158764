import Keys from "./constants";

export default class Utils {
    static getAvatar(user) {
        try {
            return user.firstName.charAt(0) + user.lastName.charAt(0);
        } catch (err) {}
    }

    static getFirstChar(s) { return s.charAt(0); }

    static toggleSidebar() {
        let el = 'body'.trim();
        document.querySelector(el).classList.toggle('toggle-sidebar');
    }

    static genIdByDateTime() {
        let date = new Date();
        let dd = String(date.getDate()).padStart(2, '0');
        let mm = String(date.getMonth() + 1).padStart(2, '0');
        let yy = String(date.getFullYear() % 100).padStart(2, '0');
        let hour = String(date.getHours()).padStart(2, '0');
        let minute = String(date.getMinutes()).padStart(2, '0');
    
        return `${yy}${mm}${dd}${hour}${minute}`;
    }

    static getTimestampDate(timestamp) {
        let date = new Date(timestamp);
        let dd = String(date.getDate()).padStart(2, '0');
        let mm = String(date.getMonth() + 1).padStart(2, '0');
        let yyyy = date.getFullYear();
        return `${dd}/${mm}/${yyyy}`;
    }

    static getTimestampDateHm(timestamp) {
        let date = new Date(timestamp);
        let dd = String(date.getDate()).padStart(2, '0');
        let mm = String(date.getMonth() + 1).padStart(2, '0');
        let yyyy = date.getFullYear();
        let hour = String(date.getHours()).padStart(2, '0');
        let minute = String(date.getMinutes()).padStart(2, '0');
    
        return `${dd}/${mm}/${yyyy} ${hour}:${minute}`;
    }

    static getMonthYear() {
        let date = new Date();
        let mm = String(date.getMonth() + 1).padStart(2, '0');
        let yyyy = date.getFullYear();

        return `${mm}/${yyyy}`;
    }

    static getMonth() {
        let date = new Date();
        let mm = String(date.getMonth() + 1).padStart(2, '0');
    
        return `${mm}`;
    }

    static getCurrentDate() {
        let date = new Date();
        let dd = String(date.getDate()).padStart(2, '0');
        let mm = String(date.getMonth() + 1).padStart(2, '0');
        let yyyy = date.getFullYear();

        return `${dd}-${mm}-${yyyy}`;
    }

    static getCurrentTime() {
        let date = new Date();
        let hour = String(date.getHours()).padStart(2, '0');
        let minute = String(date.getMinutes()).padStart(2, '0');
    
        return `${hour}:${minute}`;
    }

    
    static getCurrentDateISO() {
        let date = new Date();
        let dd = String(date.getDate()).padStart(2, '0');
        let mm = String(date.getMonth() + 1).padStart(2, '0');
        let yyyy = date.getFullYear();

        return `${yyyy}-${mm}-${dd}`;
    }

    static errorHandling (data) {
        let content = '';
        data.map((item, index) => { if (!item.key) content += `${index + 1}) ${item.value}. ` });

        return content;
    }

    static allowedRole(auth, module, type) {
        const permissions = auth.role.permissions;
        if (permissions.find(p => p.module === module && p[type])) return true;
        return false;
    }

    static truncateText = (text) => {
        return text.length > 10 ? text.slice(0, 8) + '.' : text;
    };

    static capitalizeFirstLetter = (text) => {
        return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    }

    static toggleSidebar() {
        let el = 'body'.trim();
        document.querySelector(el).classList.toggle('toggle-sidebar');
    }
}