import { useState } from "react";
import useAuth from '../../../../hooks/useAuth';
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import Utils from "../../../../utils";
import InputLabeled from '../../../../components/form/InputLabeled';
import ButtonU from "../../../../components/ui/ButtonU";
import SettingsWrapper from "../../layouts/SettingsWrapper";
import ToastU from "../../../../components/ui/ToastU";
import { useTranslation } from "react-i18next";
import AppPasswords from "./AppPasswords";

const PrivacyAndSecurity = () => {
    const { t } = useTranslation();
    const { auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const [loading, setLoading] = useState(false);
    
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPasswor] = useState('');

    const [toggleEdit, setToggleEdit] = useState(false);
    
    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    const breadcrumbs = [
        { title: t('SETTINGS')}, { title: t('PRIVACY_AND_SECURITY'), active: true }
    ]

    const handlePasswordChange = () => {
        const requiredInputs = [
            { key: currentPassword, value: t('CURRENT_PASSWORD_REQUIRED') },
            { key: newPassword, value: t('NEW_PASSWORD_REQUIRED') },
            { key: confirmNewPassword, value: t('CONFIRM_NEW_PASSWORD_REQUIRED') },
            { key: newPassword === confirmNewPassword, value: t('MATCH_PASSWORD_INSTRUCTION') }
        ]

        const content = Utils.errorHandling(requiredInputs);
        if (content) {
            setToast({ content, variant: 'danger', title: t('ERROR'), show: true });
            return;
        }

        const data = { currentPassword, newPassword, confirmNewPassword };

        axiosPrivate.put('/auth/change-password/'+auth.user.id, data).then((res) => {
            if (res.status == 200) {
                setToast({ content: t('PASSWORD_UPDATED'), variant: 'success', title: t('SUCCESS'), show: true });
                setCurrentPassword('');
                setNewPassword('');
                setConfirmNewPasswor('');
            }
        }).catch((err) => {
            if (!err?.response) {
                setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
                return;
            }
            switch(err.response.status) {
                case 402: setToast({ content: t('INCORRECT_PASSWORD'), variant: 'danger', title: t('ERROR'), show: true }); break;
                case 404: setToast({ content: `${t('USER')} ${t('NOT_FOUND')}`, variant: 'danger', title: t('ERROR'), show: true }); break;
                default : setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true }); break;
            }
        }).finally(() => {setLoading(false)});
    }

    return (
        <SettingsWrapper loading={loading} breadcrumbs={breadcrumbs}>
            <div className="d-flex flex-column">
                <span className="fs-4 medium fg-dark">{t('PRIVACY_AND_SECURITY')}</span>
                <hr />
            </div>
            <div className="mt-3">
                <span className="fs-5 medium fg-dark">{t('PASSWORD')}</span>
                <form className="row d-flex align-items-end mt-2">
                    <InputLabeled label={`${t('CURRENT')} ${t('PASSWORD')}`} type={'password'} value={currentPassword} setValue={setCurrentPassword} className={'col-md-3'} bg={'bg-purple-50'} required={toggleEdit} disabled={!toggleEdit}/>
                    <InputLabeled label={`${t('NEW')} ${t('PASSWORD')}`} type={'password'} value={newPassword} setValue={setNewPassword} className={'col-md-3'} bg={'bg-purple-50'} required={toggleEdit} disabled={!toggleEdit}/>
                    <InputLabeled label={`${t('NEW')} ${t('PASSWORD')} ${t('CONFIRM')}`} type={'password'} value={confirmNewPassword} setValue={setConfirmNewPasswor} className={'col-md-3'} bg={'bg-purple-50'} required={toggleEdit} disabled={!toggleEdit}/>
                    <div className="d-flex align-items-center col-md-3 mb-1">
                        {!toggleEdit && <ButtonU onSubmit={() => setToggleEdit(true)} className={'bg-blue-700 rounded-3 text-white'} text={t('EDIT')}/>}
                        {toggleEdit && <ButtonU onSubmit={() => setToggleEdit(false)} className={'bg-dark rounded-3 text-white'} text={t('BACK')}/>}
                        {toggleEdit && <ButtonU onSubmit={handlePasswordChange} className={`bg-green-700 rounded-3 text-white`} text={t('SAVE')}/>}
                    </div>
                </form>
            </div>
            <hr />
            <AppPasswords toast={toast} setToast={setToast} setLoading={setLoading}/>
            <ToastU toast={toast} setToast={setToast}/>
        </SettingsWrapper>
    )
}

export default PrivacyAndSecurity;