import { Link, useLocation } from "react-router-dom"
import Utils from "../utils";
import icCp from '../assets/svg/ic_cashier_machine.svg';
import { useTranslation } from "react-i18next";

const NavBar = ({...props}) => {
    const { t } = useTranslation();
    const location = useLocation();
    const items = props.items;

    const isActive = (links) => {
        let flag = links.some((link) => {
            const baseLink = link.split('/')[1];
            const path = location.pathname.split('/')[1];
            return (baseLink === path);
        });
        
        return flag;
    }

    return (
        <div className="nav-bar-container">
             <ul className="nav-bar" id="nav-bar">
                { items.map((item, index)=>{
                    return (
                        <Link to={item.links[0]} className="no-decor" key={item.id} >
                            <div className={"nav-item " + (!isActive(item.links) && 'collapsed')} data-bs-target={`#${item.id}`} data-bs-toggle="collapse">
                                <img src={item.icon} className={"icon " + item.bg} />
                                <span className="ms-2">{Utils.truncateText(item.name)}</span>
                            </div>
                        </Link>
                    );
                })}
            </ul>
            <Link to={'/cp'} className="no-decor bg-gradient bg-blue-900 font-14 text-white rounded-1 px-3 py-1">
                <img src={icCp} className="wh-18 me-2" />
                {t('CASHIER_DESK')}
            </Link>
        </div>
    );
}

export default NavBar;