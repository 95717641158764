import { useEffect, useState } from "react";
import useAxiosPrivate from '../../../../../../hooks/useAxiosPrivate';
import ModalU from '../../../../../../components/ui/ModalU';
import Utils from "../../../../../../utils";
import InputLabeled from '../../../../../../components/form/InputLabeled';
import FoundCategories from "./../../../../components/FoundCategories";
import { useTranslation } from "react-i18next";
import CategoryPath from "../../../../components/CategoryPath";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLayerGroup } from "@fortawesome/free-solid-svg-icons";

const Update = ({...props}) => {
    const { t } = useTranslation();
    const axiosPrivate = useAxiosPrivate();
    const item = props.item;
    
    const [name, setName] = useState('');
    const [parentId, setParentId] = useState('');

    const [parentCategoryName, setParentCategoryName] = useState('');

    const [parentCategory, setParentCategory] = useState({});

    const [expenseCategories, setExpenseCategories] = useState([]);
    const [categoryPath, setCategoryPath] = useState([]);

    const [disableCategoryInput, setDisableCategoryInput] = useState(false);

    const [query, setQuery] = useState({
        limit:0,
        order: [['createdAt', 'ASC']],
        filter: { name: '', parentId: '' }
    });

    useEffect(() => {
        getExpenseCategories();
    }, [query]);
    
    const handleInputOnFocus = () => {
        setQuery({...query, limit: 20});
    };

    const handleInputOnBlur = () => {
        setExpenseCategories([]);
    };

    useEffect(() => {
        if (parentCategoryName?.length < 1) {
            setExpenseCategories([]);
            return;
        }
        if (parentCategoryName && parentCategory?.name != parentCategoryName)
            setQuery({...query, limit: 50, filter: { name: parentCategoryName }});
    }, [parentCategoryName]);

    useEffect(() => {
        if (!parentCategory?.id) return;
        setParentCategoryName(parentCategory?.name);
        setParentId(parentCategory?.id);
        setExpenseCategories([]);
        
        setCategoryPath([...categoryPath, parentCategory]);

        if (parentCategory?.subcategories?.length) {
            setQuery({...query, limit: 50, filter: {...query.filter, parentId: parentCategory.id }});
        }
        else {
            setQuery({...query, limit: 0, filter: {...query.filter, parentId: '' }});
            setDisableCategoryInput(true);
        }
    }, [parentCategory]);

    const getExpenseCategories = () => {
        axiosPrivate.get('/expense-categories', { params: query }).then((res) => {
            if (res.status == 200) setExpenseCategories(res.data.data);
        });
    }

    useEffect(() => {
        setName(item.name);
        setParentId(item.parentId ? item.parentId : '');

        setParentCategory(item?.parentCategory)
        setParentCategoryName(item?.parentCategory?.name);
    }, [item])
  
    const clearCategoryPath = () => {
        setCategoryPath([]);
        setQuery({...query, limit: 20});
        setParentCategory({});
        setParentId('');
        setParentCategoryName('');
        setDisableCategoryInput(false);
    }
 
    const handleSubmit = (event) => {
        event.preventDefault();
        
        const requiredInputs = [
            { key: name, value: t('NAME_REQUIRED') },
        ]

        const content = Utils.errorHandling(requiredInputs);
        if (content) {
            props.setToast({ content, variant: 'danger', title: t('ERROR'), show: true });
            return;
        }

        props.setLoading(true);
        const data = { name, parentId };
        axiosPrivate.put('/expense-categories/update/'+item.id, data).then((res) => {
            if (res.status == 200) {
                props.setModalShow(false);
                props.setToast({ content: t('SUCCESS'), variant: 'success', title: t('SUCCESS'), show: true });
                clearCategoryPath();
            }
        }).catch((err) => {
            if (!err?.response) props.setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
            else props.setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true });
        }).finally(() => {props.setLoading(false)});
    }

    return (
        <ModalU show={props.modalShow} size={''} title={t('EXPENSE_CATEGORY')}
            actionbtntext={t('UPDATE')}
            actionbtnclassname={"bg-green-700 font-14 medium text-white"}
            onSubmit={handleSubmit} onHide={props.setModalShow}>
                
            <form onSubmit={handleSubmit}>
                <InputLabeled label={t('NAME')} type={'text'} value={name} setValue={setName} hint={t('NAME')} required={true}/>
                <Accordion elevation={1}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} className="bg-gray-50">
                        <span className="col-md-12 fg-dark font-13"><FontAwesomeIcon icon={faLayerGroup} className="me-2"/>{t('DEPENDENT_CATEGORY')}</span>
                    </AccordionSummary>
                    <AccordionDetails className="p-3">
                        <CategoryPath categoryPath={categoryPath} clearCategoryPath={clearCategoryPath}/>
                        <div className="">
                            <InputLabeled label={t('DEPENDENT_CATEGORY')} type={'text'} value={parentCategoryName} setValue={setParentCategoryName} hint={`${t('EXPENSE_CATEGORY')} ${t('FIND')}`} onFocus={handleInputOnFocus} onBlur={handleInputOnBlur} disabled={disableCategoryInput} required={true}/>
                            <FoundCategories items={expenseCategories} setItems={setExpenseCategories} setItem={setParentCategory}/>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </form>
        </ModalU>
    );
}

export default Update