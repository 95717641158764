import { useEffect, useRef, useState } from 'react';
import useAuth from "../../../../../../hooks/useAuth";
import useAxiosPrivate from '../../../../../../hooks/useAxiosPrivate';
import AdminVerification from "../../../../../../components/ui/AdminVerification";
import ModalU from '../../../../../../components/ui/ModalU';
import Keys from "../../../../../../utils/constants";
import ToastU from "../../../../../../components/ui/ToastU";
import { useTranslation } from "react-i18next";
import ButtonU from '../../../../../../components/ui/ButtonU';
import InvisibleInput from '../../../../components/ui/InvisibleInput';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const ClearSaleItems = ({...props}) => {
    const { t } = useTranslation();
    const { setTempUnlocked } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const [adminVerificationModal, setAdminVerificationModal] = useState(false);
    const enterRef = useRef();
    
    const sale = props.sale;
    const items = props.items;

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    useEffect(() => {
        enterRef?.current?.focus();
    }, [props.modalShow]);

    const handleSubmit = (event) => {
        event.preventDefault();
        setAdminVerificationModal(true);
    }

    const setOnSuccess = () => {
        setTempUnlocked(false);

        axiosPrivate.delete('/sale-items/delete-bulk', { data: sale }).then((res) => {
            if (res.status == 200) {
                props.setModalShow(false);
                props.setToast({ content: t('SUCCESS'), variant: 'success', title: t('SUCCESS'), show: true });
                props.refresh();
            }
        }).catch((err) => {
            if (!err?.response) props.setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
            else props.setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true });
        });
    }

    return (
        <ModalU show={props.modalShow} size={''} title={t('WARNING')} onHide={props.setModalShow}>
            <form className="d-flex flex-column align-items-center p-3" onSubmit={handleSubmit}>
                <span className="medium fg-red-300 font-20">{t('DATA_DELETION_CONFIRMATION')}</span>
                <div className="d-flex flex-column my-2">
                    <span className="medium fg-dark">{t('SALE')} {t('PRODUCTS')}</span>
                    <hr className='my-2'/>
                    {items.map((item, index) => {
                        return (<span key={item.id} className="font-14 fg-dark me-1">{index+1}. {item.product && item.product.name}</span>)
                    })}
                </div>
                <InvisibleInput enterRef={enterRef}/>
                <ButtonU type={'submit'} onSubmit={handleSubmit} icon={faCheck} text={t('DELETE')} className={"bg-red-200 border-danger fg-red-500 bg-gradient px-5"}/>
            </form>
            <ToastU toast={toast} setToast={setToast}/>
            <AdminVerification
                show={adminVerificationModal}
                onHide={setAdminVerificationModal}
                appName={Keys.CP}
                setOnSuccess={setOnSuccess}
                toast={toast}
                setToast={setToast}
            />
        </ModalU>
    );
}

export default ClearSaleItems