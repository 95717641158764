import { useEffect, useState } from "react";
import useAxiosPrivate from '../../../../../hooks/useAxiosPrivate';
import ModalU from '../../../../../components/ui/ModalU';
import Utils from "../../../../../utils";
import InputLabeled from '../../../../../components/form/InputLabeled';
import FoundCategories from '../../../../Settings/components/FoundCategories';
import { useTranslation } from "react-i18next";
import InputAreaLabeled from "../../../../../components/form/InputAreaLabeled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const Update = ({...props}) => {
    const { t } = useTranslation();
    const axiosPrivate = useAxiosPrivate();
    const item = props.item;
    
    const [amount, setAmount] = useState('');
    const [expenseCategoryId, setExpenseCategoryId] = useState('');
    const [description, setDescription] = useState('');

    const [expenseCategoryName, setExpenseCategoryName] = useState('');

    const [expenseCategory, setExpenseCategory] = useState({});

    const [expenseCategories, setExpenseCategories] = useState([]);
    const [categoryPath, setCategoryPath] = useState([]);

    const [disableCategoryInput, setDisableCategoryInput] = useState(false);

    const [query, setQuery] = useState({
        limit:0,
        order: [['createdAt', 'ASC']],
        filter: { name: '', parentId: '' }
    });

    useEffect(() => {
        setAmount(item?.amount);
        setDescription(item?.description);
        setExpenseCategoryId(item?.expenseCategoryId);
    }, [item]);

    useEffect(() => {
        getExpenseCategories();
    }, [query]);
    
    const handleInputOnFocus = () => {
        setQuery({...query, limit: 20});
    };

    const handleInputOnBlur = () => {
        setExpenseCategories([]);
    };

    useEffect(() => {
        if (expenseCategoryName.length < 1) {
            setExpenseCategories([]);
            return;
        }
        if (expenseCategoryName && expenseCategory.name != expenseCategoryName)
            setQuery({...query, limit: 50, filter: {...query.filter, name: expenseCategoryName }});
    }, [expenseCategoryName]);

    useEffect(() => {
        if (!expenseCategory.id) return;
        setExpenseCategoryName(expenseCategory.name);
        setExpenseCategoryId(expenseCategory.id);
        setExpenseCategories([]);

        setCategoryPath([...categoryPath, expenseCategory]);

        if (expenseCategory?.subcategories?.length) {
            setQuery({...query, limit: 50, filter: {...query.filter, parentId: expenseCategory.id }});
        }
        else {
            setQuery({...query, limit: 0, filter: {...query.filter, parentId: '' }});
            setDisableCategoryInput(true);
        }

    }, [expenseCategory]);

    const getExpenseCategories = () => {
        axiosPrivate.get('/expense-categories', { params: query }).then((res) => {
            if (res.status == 200) setExpenseCategories(res.data.data);
        });
    }

    const clearCategoryPath = () => {
        setCategoryPath([]);
        setQuery({...query, limit: 20});
        setExpenseCategory({});
        setExpenseCategoryId('');
        setExpenseCategoryName('');
        setDisableCategoryInput(false);
    }

    const clearForm = () => {
        setAmount('');
        setDescription('');
        clearCategoryPath();
    }
 
    const handleSubmit = (event) => {
        event.preventDefault();
        
        const requiredInputs = [
            { key: amount, value: t('AMOUNT_REQUIRED') },
            { key: expenseCategoryId, value: t('CATEGORY_REQUIRED') },
        ]

        const content = Utils.errorHandling(requiredInputs);
        if (content) {
            props.setToast({ content, variant: 'danger', title: t('ERROR'), show: true });
            return;
        }

        props.setLoading(true);
        const data = { amount, expenseCategoryId, description };
        axiosPrivate.put('/expenses/update/'+item.id, data).then((res) => {
            if (res.status == 200) {
                props.setModalShow(false);
                props.setToast({ content: t('SUCCESS'), variant: 'success', title: t('SUCCESS'), show: true });
                clearForm();
            }
        }).catch((err) => {
            if (!err?.response) props.setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
            else props.setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true });
        }).finally(() => {props.setLoading(false)});
    }

    return (
        <ModalU show={props.modalShow} size={'lg'} title={t('EXPENSE')}
            actionbtntext={t('UPDATE')}
            actionbtnclassname={"bg-green-600 font-14 medium text-white"}
            onSubmit={handleSubmit} onHide={props.setModalShow}>
                
            <form onSubmit={handleSubmit} className="row m-3">
                {categoryPath?.length != 0 && <span className="fg-red-300 medium font-13 mb-2 hover-cursor"
                    onClick={clearCategoryPath}>
                    <FontAwesomeIcon icon={faXmark} className="me-1"/>
                    {t('CLEAR')}
                </span>}
                <div className="d-flex flex-row mb-2">
                    {categoryPath?.map((item) => {
                        return ( <span key={item.id} className="font-12 fg-dark">/<span className="fg-blue-500 mx-1 border rounded px-2 py-1">{item?.name}</span></span>)
                    })}
                </div>
                <div className="col-md-8">
                    <InputLabeled label={t('EXPENSE_CATEGORY')} type={'text'} value={expenseCategoryName} setValue={setExpenseCategoryName} hint={`${t('EXPENSE_CATEGORY')} ${t('FIND')}`} onFocus={handleInputOnFocus} onBlur={handleInputOnBlur} disabled={disableCategoryInput} required={true}/>
                    <FoundCategories items={expenseCategories} setItems={setExpenseCategories} setItem={setExpenseCategory}/>
                </div>
                <InputLabeled label={t('AMOUNT')} type={'number'} value={amount} setValue={setAmount} hint={t('100')} className={'col-md-4'} required={true}/>
                <InputAreaLabeled label={t('DEFINITION')} type={'text'} value={description} setValue={setDescription} rows={3} hint={t('DEFINITION')} className={'mt-3'}/>
            </form>
        </ModalU>
    );
}

export default Update