import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ButtonU = ({...props}) => {

    return(
        <button type={props.type} onClick={props.onSubmit} className={`btn button-u mx-1 border ${props.className}`} disabled={props.disabled}>
            {props.icon && <FontAwesomeIcon className="mx-1 font-16" icon={props.icon}/>}
            {props.text && <span className="mx-1 medium">{props.text}</span>}
        </button>
    );
}

export default ButtonU;