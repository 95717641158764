import { useEffect, useRef, useState } from "react";
import BarcodeReader  from "react-barcode-reader";
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import ModalU from '../../../../components/ui/ModalU';
import Keys from "../../../../utils/constants";
import InputLabeled from "../../../../components/form/InputLabeled";
import { useTranslation } from "react-i18next";

const FindProduct = ({...props}) => {
    const axiosPrivate = useAxiosPrivate();
    const { t } = useTranslation();
    const barcodeRef = useRef(null);
    
    const [barcode, setBarcode] = useState('');

    const [product, setProduct] = useState({});
    const [showNotFound, setShowNotFound] = useState(false);

    useEffect(() => {
        barcodeRef?.current?.focus();
    }, [props.modalShow])


    const getProduct = (barcode) => {
        let id = undefined;
        axiosPrivate.get('/products/'+id+'/'+barcode).then((res) => {
            if (res.status == 200) {
                setProduct(res.data.data);
                setShowNotFound(false);
            }
        }).catch((err) => {
            switch(err.response.status) {
                case 404:
                    setProduct({});
                    setShowNotFound(true);
                    break;
                default: break;
            }
        }).finally(() => setBarcode(''));
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        getProduct(barcode);
    }

    const handleScan = (data) => {
        getProduct(data);
    }

    const handleError = (err) => {
        console.error(err);
    }

    return (
        <ModalU show={props.modalShow} size={''} title={`${t('PRODUCT')} ${t('FIND')}`} onHide={props.setModalShow}>
            <BarcodeReader onScan={handleScan} onError={handleError}/>
            <form onSubmit={handleSubmit} className="d-flex flex-column">
                <div className="col-md-12">
                    <InputLabeled label={t('BAR_CODE')} type={'number'} value={barcode} setValue={setBarcode} innerRef={barcodeRef} hint={t('BAR_CODE')}/>
                </div>
                {product.id && <div className="d-flex flex-column align-items-center border bg-green-50 p-2 rounded-2 mt-2 fs-3">
                    <span className="fg-dark font-14 bold">{t('BAR_CODE')}: {product?.barcode}</span>
                    <span className="fg-gray">{product?.name}</span>
                    <span className="fg-green-500 medium">{product?.sellingPrice?.toFixed(2)} {Keys.TMT}</span>
                </div>}
                {showNotFound && <span className="font-20 bold fg-red-300 bg-red-50 text-center my-3 px-2 py-1 rounded-1">{t('NOT_FOUND')}</span>}
            </form>
        </ModalU>
    );
}

export default FindProduct