import { useState } from 'react';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import TableStd from '../../../../../../../components/ui/TableStd';
import { useTranslation } from "react-i18next";
import Edit from './Edit';
import Actions from "../../../../../../../components/ui/Actions";

const InvoiceItems = ({...props}) => {
    const { t } = useTranslation();

    const items = props.items;

    const [modalEdit, setModalEdit] = useState(false);

    const [itemToBe, setItemToBe] = useState({});

    const handleDeleteItem = (item) => {
        const updatedItems = items.filter((e) => e.productId != item.productId);
        props.setItems(updatedItems);
    }

    const modelProps = [ 
        {
            name: t('BAR_CODE'),
            value: (prop) => { return( <>{prop.product.barcode}</> ) }
        },
        { 
            name: t('PRODUCT'),
            value: (prop) => { return(<>{prop.product.name}</>) } 
        },
        {
            name: t('STOCK'),
            value: (prop) => { return(<>{parseFloat(prop.product.quantity)}</>) }
        },
        {
            name: t('QUANTITY'),
            value: (prop) => { return(<>{parseFloat(prop.quantity)}</>) }
        },
        {
            name: t('UNIT'),
            value: (prop) => { return(<>{prop.product.unit.shortName}</>) }
        },
        {
            name: "",
            value: (prop) => {
                const options =[
                    {
                        icon: faPenToSquare,
                        style: "fg-blue-500 font-14",
                        onClick: () => {
                            setItemToBe(prop);
                            setModalEdit(true);
                        }
                    },
                    {
                        icon: faTrashCan,
                        style: "fg-red-300 font-14",
                        onClick: () => { 
                            setItemToBe(prop);
                            handleDeleteItem(prop);
                        }
                    }
                ]
                return (<Actions items={options}/>)
            }
        }
    ];

    return (
        <div className='d-flex flex-column'>
            <div>
                <TableStd modelProps={modelProps} data={items}/>
                    { itemToBe.product && 
                        <Edit
                            modalShow={modalEdit}
                            setModalShow={setModalEdit}
                            item={itemToBe}
                            items={props.items}
                            setItems={props.setItems}
                        />
                    }
            </div>
        </div>
    )
}

export default InvoiceItems;