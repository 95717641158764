import { useEffect, useRef, useState } from 'react';
import useAuth from "../../../../../../hooks/useAuth";
import AdminVerification from "../../../../../../components/ui/AdminVerification";
import ModalU from '../../../../../../components/ui/ModalU';
import Keys from "../../../../../../utils/constants";
import ToastU from "../../../../../../components/ui/ToastU";
import { useTranslation } from "react-i18next";
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import ButtonU from '../../../../../../components/ui/ButtonU';
import InvisibleInput from '../../../../components/ui/InvisibleInput';

const DeleteItem = ({...props}) => {
    const { t } = useTranslation();
    const { setTempUnlocked } = useAuth();
    const [adminVerificationModal, setAdminVerificationModal] = useState(false);
    const enterRef = useRef();
    
    const item = props.item;

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    useEffect(() => {
        enterRef?.current?.focus();
    }, [props.modalShow]);

    const handleSubmit = (event) => {
        event.preventDefault();
        setAdminVerificationModal(true);
    }

    const setOnSuccess = () => {
        setTempUnlocked(false);

        const updatedItems = props.items.filter((e) => e.productId != item.productId);
        props.setItems(updatedItems);
        props.setModalShow(false);
    }

    return (
        <ModalU show={props.modalShow} size={''} title={t('WARNING')} onHide={props.setModalShow}>   
            <form className="d-flex flex-column align-items-center p-3" onSubmit={handleSubmit}>
                <span className="medium fg-red-300 font-20">{t('DATA_DELETION_CONFIRMATION')}</span>
                <div className="d-flex flex-row my-2">
                    <span className="medium fg-dark me-2">{t('PRODUCT')}:</span>
                    <span className="fg-dark me-1">{item?.product?.name}</span>
                </div>
                <InvisibleInput enterRef={enterRef}/>
                <ButtonU type={'submit'} onSubmit={handleSubmit} icon={faCheck} text={t('DELETE')} className={"bg-red-200 border-danger fg-red-500 bg-gradient px-5"}/>
            </form>
            <ToastU toast={toast} setToast={setToast}/>
            <AdminVerification
                show={adminVerificationModal}
                onHide={setAdminVerificationModal}
                appName={Keys.CP}
                setOnSuccess={setOnSuccess}
                toast={toast}
                setToast={setToast}
            />
        </ModalU>
    );
}

export default DeleteItem