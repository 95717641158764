import { useEffect, useState } from "react";
import useAuth from "../../../../../hooks/useAuth";
import useAxiosPrivate from '../../../../../hooks/useAxiosPrivate';
import ModalU from '../../../../../components/ui/ModalU';
import Utils from "../../../../../utils";
import InputLabeled from '../../../../../components/form/InputLabeled';
import SelectLabeled from '../../../../../components/form/SelectLabeled';
import { useTranslation } from "react-i18next";

const Update = ({...props}) => {
    const { t } = useTranslation();
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const item = props.item;
    
    const [companyName, setCompanyName] = useState('');
    const [contactName, setContactName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumberWork, setPhoneNumberWork] = useState('');
    const [fax, setFax] = useState('');
    const [provinceId, setProvinceId] = useState('');
    const [districtId, setDistrictId] = useState('');
    const [addressLine, setAddressLine] = useState('');
    const [postalCode, setPostalCode] = useState();
    const [website, setWebsite] = useState('');

    const [provinces, setProvinces] = useState([]);
    const [districts, setDistricts] = useState([]);

    useEffect(() => {
        setCompanyName(item.companyName);
        setContactName(item.contactName);
        setEmail(item.email);
        setPhoneNumber(item.phoneNumber);
        setPhoneNumberWork(item.phoneNumberWork);
        setFax(item.fax);
        setProvinceId(item.provinceId);
        setDistrictId(item.districtId);
        setAddressLine(item.addressLine);
        setPostalCode(item.postalCode);
        setWebsite(item.website);
    }, [item])

    useEffect(() => {
        if (ready) {
            getProvinces();
        }
    }, [ready]);

    useEffect(() => {
        if (ready) {
            getDistricts();
        }
    }, [provinceId])

    const getProvinces = () => {
        props.setLoading(true);
        const query = { order: [['name', 'ASC']] };
        axiosPrivate.get('/provinces', { params: query }).then((res) => {
            if (res.status == 200) setProvinces(res.data.data);
        }).finally(() => {props.setLoading(false)});
    }

    const getDistricts = () => {
        if (!provinceId) return;
        
        props.setLoading(true);
        const query = { order: [['name', 'ASC']], filter: { provinceId } };
        axiosPrivate.get('/districts', { params: query }).then((res) => {
            if (res.status == 200) setDistricts(res.data.data);
        }).finally(() => {props.setLoading(false)});
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        const requiredInputs = [
            { key: companyName, value: t('COMPANY_NAME_REQUIRED') },
            { key: contactName, value: t('CONTACT_NAME_REQUIRED') },
            { key: provinceId, value: t('PROVINCE_REQUIRED') },
            { key: districtId, value: t('DISTRICT_REQUIRED') },
            { key: phoneNumber, value: t('PHONE_NUMBER_REQUIRED') },
            { key: addressLine, value: t('ADDRESS_LINE_REQUIRED') },
        ]

        const content = Utils.errorHandling(requiredInputs);
        if (content) {
            props.setToast({ content, variant: 'danger', title: t('ERROR'), show: true });
            return;
        }

        props.setLoading(true);
        const data = { companyName, contactName, email, phoneNumber, phoneNumberWork, fax, website, provinceId, districtId, addressLine, postalCode };
        axiosPrivate.put('/suppliers/update/'+item.id, data).then((res) => {
            if (res.status == 200) {
                props.setModalShow(false);
                props.setToast({ content: t('SUCCESS'), variant: 'success', title: t('SUCCESS'), show: true });
            }
        }).catch((err) => {
            if (!err?.response) props.setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
            else props.setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true });
        }).finally(() => {props.setLoading(false)});
    }

    return (
        <ModalU show={props.modalShow} size={'lg'} title={`${t('SUPPLIER')} [${t('EDIT')}]`}
            actionbtntext={t('UPDATE')}
            actionbtnclassname={"bg-green-700 font-14 medium text-white"}
            onSubmit={handleSubmit} onHide={props.setModalShow}>
                
            <form onSubmit={handleSubmit}>
                <div className="d-flex row">
                    <div className="col-md-6">
                        <InputLabeled label={t('COMPANY_NAME')} type={'text'} value={companyName} setValue={setCompanyName} hint={t('HINT_COMPANY')} required={true}/>
                    </div>
                    <div className="col-md-6">
                        <InputLabeled label={t('CONTACT_NAME')} type={'text'} value={contactName} setValue={setContactName} hint={t('FULLNAME')} required={true}/>
                    </div>
                </div>
                <div className="d-flex row">
                    <div className="col-md-4">
                        <InputLabeled label={t('PHONE_NUMBER')} type={'number'} value={phoneNumber} setValue={setPhoneNumber} hint={t('HINT_PHONE_NUMBER')} required={true}/>
                    </div>
                    <div className="col-md-4">
                        <InputLabeled label={`${t('PHONE_NUMBER')} ${t('WORK')}`} type={'number'} value={phoneNumberWork} setValue={setPhoneNumberWork} hint={t('HINT_PHONE_NUMBER_WORK')}/>
                    </div>
                    <div className="col-md-4">
                        <InputLabeled label={t('FAX')} type={'number'} value={fax} setValue={setFax} hint={t('HINT_PHONE_NUMBER_WORK')}/>
                    </div>
                </div>
                <div className="d-flex row">
                    <div className="col-md-4">
                        <SelectLabeled label={t('PROVINCE')} items={provinces} value={provinceId} setValue={setProvinceId} required={true}/>
                    </div>
                    <div className="col-md-4">
                        <SelectLabeled label={t('DISTRICT')} items={districts} value={districtId} setValue={setDistrictId} required={true}/>
                    </div>
                    <div className="col-md-4">
                        <InputLabeled label={t('POSTAL_CODE')} type={'number'} value={postalCode} setValue={setPostalCode} hint={t('HINT_POSTAL_CODE')}/>
                    </div>
                </div>
                <div className="d-flex row">
                    <div className="col-md-4">
                        <InputLabeled label={t('EMAIL')} type={'text'} value={email} setValue={setEmail} hint={t('HINT_EMAIL')}/>
                    </div>
                    <div className="col-md-4">
                        <InputLabeled label={t('WEBSITE')} type={'text'} value={website} setValue={setWebsite} hint={t('HINT_WEBSITE')}/>
                    </div>
                    <div className="col-md-4">
                        <InputLabeled label={t('ADDRESS')} type={'text'} value={addressLine} setValue={setAddressLine} hint={t('HINT_ADDRESS')} required={true}/>
                    </div>
                </div>
            </form>
        </ModalU>
    );
}

export default Update