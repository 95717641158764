import { useState } from 'react';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import TableStd from '../../../../../../../components/ui/TableStd';
import { useTranslation } from "react-i18next";
import Utils from "../../../../../../../utils";
import Edit from './Edit';
import Actions from "../../../../../../../components/ui/Actions";
import Chekbox from "@mui/material/Checkbox";
import DeleteAuditedProduct from './DeleteAuditedProduct';
import Keys from '../../../../../../../utils/constants';
import Status from "../../../../../../../components/ui/Status";
import { Link } from 'react-router-dom';

const AuditedProducts = ({...props}) => {
    const { t } = useTranslation();

    const items = props.items;

    const [modalEdit, setModalEdit] = useState(false);
    const [modalDelete, setModalDelete] = useState(false);

    const [itemToBe, setItemToBe] = useState({});

    const modelProps = [ 
        {
            name: t('BAR_CODE'),
            value: (prop) => {
                return(
                    <div className='d-flex flex-row align-items-center'>
                        <Chekbox checked={prop.checked} size="small" className='py-0' disabled={prop.closed}
                            onChange={(e) => {
                                const updatedItems = props.items.map((item) => {
                                    if (item.productId == prop.productId)
                                        return {...item, checked: e.target.checked};
                                    return item;
                                });
                                props.setItems(updatedItems);
                            }}/>
                        <span>{prop.product.barcode}</span>
                    </div>
                );
            }
        },
        { 
            name: t('PRODUCT'),
            value: (prop) => { return(<Link to={`/product/products/${prop?.product?.id}`} className='no-decor'>{prop?.product?.name}</Link>) } 
        },
        {
            name: t('STOCK'),
            value: (prop) => { return(<>{!prop.closed ? `${prop?.product?.quantity?.toFixed(2)} ${prop?.product?.unit?.shortName}` : '-'}</>) }
        },
        {
            name: `${t('QUANTITY')} (${t('INSUFFICIENT')})`,
            value: (prop) => { return(<>{prop?.quantity} {prop?.product?.unit?.shortName}</>) }
        },
        {
            name: t('UNIT_PRICE'),
            value: (prop) => { return(<>{prop?.unitPrice?.toFixed(2)} {Keys.TMT}</>) }
        },
        {
            name: t('TOTAL'),
            value: (prop) => { return(<>{prop?.total?.toFixed(2)} {Keys.TMT}</>) }
        },
        {
            name: t('CLOSED'),
            value: (prop) => { return(<Status status={prop.closed}/>) }
        },
        {
            name: t('CREATED_DATE'),
            value: (prop) => { return(<>{Utils.getTimestampDate(prop.createdAt)}</>) }
        },
        {
            name: t('UPDATED_AT'),
            value: (prop) => { return(<>{Utils.getTimestampDate(prop.updatedAt)}</>) }
        },
        {
            name: t('RESPONSIBLE'),
            value: (prop) => { return(<>{prop?.user?.username}</>) }
        },
        {
            name: t('DEFINITION'),
            value: (prop) => { return(<>{prop.description}</>) }
        },
        {
            name: "",
            value: (prop) => {
                const options =[
                    {
                        icon: faPenToSquare,
                        style: prop.closed ? "fg-gray font-14" : "fg-blue-500 font-14",
                        onClick: () => {
                            setItemToBe(prop);
                            setModalEdit(!prop.closed);
                        }
                    },
                    {
                        icon: faTrashCan,
                        style: props.closed ? "fg-gray font-14" : "fg-red-300 font-14",
                        onClick: () => { 
                            setItemToBe(prop);
                            setModalDelete(!props.closed);
                        }
                    }
                ]
                return (<Actions items={options}/>)
            }
        }
    ];

    return (
        <div className='d-flex flex-column'>
            {items?.length > 0 && <TableStd modelProps={modelProps} data={items}/>}
            { itemToBe.product && 
                <Edit
                    modalShow={modalEdit}
                    setModalShow={setModalEdit}
                    audit={props.audit}
                    item={itemToBe}
                    items={props.items}
                    setItems={props.setItems}
                    toast={props.toast}
                    setToast={props.setToast}
                    refresh={props.refresh}
                />
            }
            { itemToBe.product &&
                <DeleteAuditedProduct
                    modalShow={modalDelete}
                    setModalShow={setModalDelete}
                    toast={props.toast}
                    setToast={props.setToast}
                    item={itemToBe}
                    refresh={props.refresh}
                />
            }
        </div>
    )
}

export default AuditedProducts;