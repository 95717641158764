import { Table } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

const ReturnedSaleItems = ({...props}) => {
    const { t } = useTranslation();
    
    const modelProps = [
        {
            name: t('PRODUCT'),
            value: (prop) => { return(
                <div className="d-flex flex-column">
                    <span className="medium">{prop.product.name}</span>
                    <span>{prop.product.barcode}</span>
                </div>
            )}
        },
        {
            name: t('QUANTITY'),
            value: (prop) => { return(<>x{prop.quantity} {prop?.product?.unit?.shortName}</>) }
        },
        {
            name: t('UNIT_PRICE'),
            value: (prop) => { return(<span className="fg-green-500">{prop.sellingPrice.toFixed(2)}</span>) }
        },
        {
            name: t('TOTAL'),
            value: (prop) => { return(<span className="fg-green-500">{prop.total.toFixed(2)}</span>) }
        },
    ];

    return (
        <div className='table-scroll mb-3'>
            <Table hover striped className='small overflow-hidden rounded-2 bg-light'>
                <thead style={{ position: "sticky", top: 0, margin: 0}}>
                    <tr className='small'>
                        { modelProps.map((prop) => {
                            return (<th className='bg-dark text-white border-white' scope='col' key={prop.name}>{prop.name}</th>)
                        })}
                    </tr>
                </thead>
                <tbody>
                    {props.data.map((row, index) => {
                        return(
                            <tr key={index} onClick={() => {
                                    props.setActiveItemIndex(index);
                                    props.setActiveItem(row);
                                }}
                                className='small hover-cursor'>
                                { modelProps.map((prop) => {
                                    return (<td className='table-cell py-2 fg-dark' key={prop.name + row.id}>{ prop.value(row) }</td>)
                                })}
                            </tr>
                        )
                    })}
                </tbody>
                <tfoot>
                    <tr>
                        <th className='py-1'>
                            <code className="font-14 ms-2">{t('TOTAL')} {t('ITEM')}: {props.data.length}</code>
                        </th>
                    </tr>
                </tfoot>
            </Table>
        </div>
    )
}

export default ReturnedSaleItems;