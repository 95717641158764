import { Table } from 'react-bootstrap';
import Utils from "../../../../../../utils";
import Keys from "../../../../../../utils/constants";
import { useTranslation } from "react-i18next";
import PaymentTypes from '../../../../../../components/ui/PaymentTypes';

const SaleItems = ({...props}) => {
    const { t } = useTranslation();
    const PAYMENT_TYPES = PaymentTypes();

    const modelProps = [
        {
            name: t('PRODUCT'),
            value: (prop) => { return(
                <div className="d-flex flex-column">
                    <span className="medium">{prop?.product?.name}</span>
                    <span>{prop?.product?.barcode}</span>
                </div>
            )}
        },
        {
            name: t('QUANTITY'),
            value: (prop) => { return(<>x{prop?.quantity} {prop?.product?.unit?.shortName}</>) }
        },
        {
            name: t('RETURNED'),
            value: (prop) => { return(<>x{prop?.returned} {prop?.product?.unit?.shortName}</>) }
        },
        {
            name: t('UNIT_PRICE'),
            value: (prop) => { return(<>{prop?.sellingPrice?.toFixed(2)}</>) }
        },
        {
            name: t('TOTAL'),
            value: (prop) => { return(<>{prop?.total?.toFixed(2)}</>) }
        },
    ];

    return (
        <div className='d-flex flex-column bg-light rounded-1'>
            <div className='d-flex flex-row justify-content-between px-2'>
                <div className='d-flex flex-column'>
                    <code className='medium text-secondary'>{t('SALE_NO')}:</code>
                    <code className='fg-dark font-12'>{props?.sale?.number}</code>
                </div>
                <div className='d-flex flex-column'>
                    <code className='medium text-secondary'>{t('DATE')}:</code>
                    <code className='fg-dark font-12'>{Utils.getTimestampDateHm(props?.sale?.createdAt)}</code>
                </div>
                <div className='d-flex flex-column'>
                    <code className='medium text-secondary'>{t('CASHIER')}:</code>
                    <code className='fg-dark font-12'>{props?.sale?.cashier?.fullName}</code>
                </div>
            </div>
            <div className='table-scroll' style={{height: "350px"}}>
                <Table hover className='small overflow-hidden rounded-2 bg-light'>
                    <thead style={{ position: "sticky", top: 0, margin: 0}}>
                        <tr className='small'>
                            { modelProps.map((prop) => {
                                return (<th className='bg-pink-700 text-white border-white' scope='col' key={prop.name}>{prop.name}</th>)
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {props?.sale?.saleItems?.map((saleItem) => {
                            return(
                                <tr key={saleItem?.id}>
                                    { modelProps.map((prop) => {
                                        let exist = false;
                                        props?.saleItemReturns.map(saleItemReturn => {if(saleItemReturn?.product?.id == saleItem?.product?.id) exist = true});
                                        const bg = exist ? 'bg-orange-200' : (saleItem?.quantity - saleItem?.returned) <= 0 ? 'bg-red-200' : 'bg-white';
                                        return (<td className={`table-cell ${bg}`} key={prop?.name + saleItem?.id}>{ prop.value(saleItem) }</td>)
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </div>
            <div className='d-flex flex-column px-2 bg-green-50'>
                <div className='d-flex flex-row align-items-start justify-content-between'>
                    <div className='d-flex flex-column'>
                        <div className='d-flex flex-row'>
                            <code className='medium text-secondary me-1'>{t('ITEM')}:</code>
                            <code className='medium fg-dark'>{props?.sale?.saleItems?.length}</code>
                        </div>
                        <div className='d-flex flex-row'>
                            <code className='medium text-secondary me-1'>{t('PAYMENT_TYPE')}:</code>
                            <code className='medium fg-dark'>{PAYMENT_TYPES.get(props?.sale?.paymentType)}</code>
                        </div>
                    </div>
                    <div className='d-flex flex-row'>
                        <code className='medium text-secondary'>{t('DISCOUNT')}: </code>
                        <code className='medium fg-dark'>{props?.sale?.discountExtra}%</code>
                    </div>
                    <div className='d-flex flex-column align-items-end'>
                        <code className='medium text-secondary'>{t('TOTAL')}</code>
                        <code className='medium fg-dark font-18'>{props?.sale?.total?.toFixed(2)} {Keys.TMT}</code>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SaleItems;