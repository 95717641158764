import { useEffect, useState } from "react";
import useAuth from "../../../../hooks/useAuth";
import Utils from "../../../../utils";
import MainWrapper from "../../layouts/MainWrapper";
import SideBar from "../../../../layouts/SideBar";
import { useTranslation } from "react-i18next";
import Keys from "../../../../utils/constants";
import icMoneyInsert from "./../../../../assets/svg/ic_money_insert.svg";
import icBookUser from './../../../../assets/svg/ic_book_user.svg';
import icCodeBranch from './../../../../assets/svg/ic_code_branch.svg';

const CompanyWrapper = (props) => {
    const { t } = useTranslation();
    const { ready, auth } = useAuth();
    const [allowed, setAllowed] = useState(false);

    useEffect(() => {
        if (ready) setAllowed(Utils.allowedRole(auth, '1004', 'read'));
    }, [ready, allowed]);

    const items = [
        { name: t('EXPENSES'), id: "expenses", icon: icMoneyInsert, links: Keys.COMPANY_EXPENSES_LINKS},
        { name: t('SUPPLIERS'), id: "suppliers", icon:icBookUser, links: Keys.COMPANY_SUPPLIERS_LINKS},
        { name: t('CONTACTS'), id: "contacts", icon:icBookUser, links: Keys.COMPANY_CONTACTS_LINKS},
        { name: t('BRANCHES'), id: "branches", icon:icCodeBranch, links: Keys.COMPANY_BRANCHES_LINKS},
    ];

    return (
        <MainWrapper loading={props.loading} breadcrumbs={props.breadcrumbs}>
            {allowed && <div className="main-page">
                <SideBar items={items}/>
                <div className="body-page">
                    { props.children }
                </div>
            </div>}
        </MainWrapper>
    );
};

export default CompanyWrapper;