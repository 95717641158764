import { useEffect, useState } from "react";
import useAxiosPrivate from '../../../../../../hooks/useAxiosPrivate';
import ModalU from '../../../../../../components/ui/ModalU';
import Utils from "../../../../../../utils";
import TableStd from '../../../../../../components/ui/TableStd';
import InputLabeled from '../../../../../../components/form/InputLabeled';
import { Checkbox } from "@mui/material";
import { useTranslation } from "react-i18next";
import Modules from "../../../../../../components/ui/Modules.jsx"

const Update = ({...props}) => {
    const { t } = useTranslation();
    const axiosPrivate = useAxiosPrivate();
    const item = props.item;
    const [permissions, setPermissions] = useState([]);
    const MODULES = Modules();
    
    const [name, setName] = useState('');
    const [code, setCode] = useState('');

    useEffect(() => {
        setName(item.name);
        setCode(item.code);
        setPermissions(item.permissions);
    }, [item]);

    const handleSubmit = (event) => {
        event.preventDefault();
        
        const requiredInputs = [
            { key: name, value: t('NAME_REQUIRED') },
            { key: code, value: t('CODE_REQUIRED') },
        ]

        const content = Utils.errorHandling(requiredInputs);
        if (content) {
            props.setToast({ content, variant: 'danger', title: t('ERROR'), show: true });
            return;
        }

        props.setLoading(true);
        const data = { name, code, permissions };
        axiosPrivate.put('/roles/update/'+item.id, data).then((res) => {
            if (res.status == 200) {
                props.setModalShow(false);
                props.setToast({ content: t('SUCCESS'), variant: 'success', title: t('SUCCESS'), show: true });
            }
        }).catch((err) => {
            if (!err?.response) {
                props.setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
                return;
            }
            switch(err.response.status) {
                case 406: props.setToast({ content: t('NOT_ALLOWED'), variant: 'danger', title: t('ERROR'), show: true }); break;
                case 400: props.setToast({ content: t('SOMETHING_WENT_WRONG'), variant: 'danger', title: t('ERROR'), show: true }); break;
                default : props.setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true }); break;
            }
        }).finally(() => {props.setLoading(false)});
    }

    const handleChangeRead = (e, prop) => {
        const updatedData = permissions.map(p => p.module === prop.module ? {...p, read: e.target.checked} : p);
        setPermissions(updatedData);
    }

    const handleChangeWrite = (e, prop) => {
        const updatedData = permissions.map(p => p.module === prop.module ? {...p, write: e.target.checked} : p);
        setPermissions(updatedData);
    }

    const handleChangeUpdate = (e, prop) => {
        const updatedData = permissions.map(p => p.module === prop.module ? {...p, update: e.target.checked} : p);
        setPermissions(updatedData);
    }

    const handleChangeDelete = (e, prop) => {
        const updatedData = permissions.map(p => p.module === prop.module ? {...p, delete: e.target.checked} : p);
        setPermissions(updatedData);
    }

    const modelProps = [
        { 
            name: t('MODUL'),
            value: (prop) => { return(<>{MODULES.get(prop.module)}</>) } 
        },
        {
            name: t('READ'),
            value: (prop) => {
                return(<Checkbox checked={prop.read} onChange={(e) => { handleChangeRead(e, prop) }} size="small" className="py-0"/>
            )}
        },
        {
            name: t('WRITE'),
            value: (prop) => {
                return(<Checkbox checked={prop.write} onChange={(e) => { handleChangeWrite(e, prop) }} size="small" className="py-0"/>
            )}
        },
        {
            name: t('UPDATE_'),
            value: (prop) => {
                return(<Checkbox checked={prop.update} onChange={(e) => { handleChangeUpdate(e, prop) }} size="small" className="py-0"/>
            )}
        },
        {
            name: t('DELETE_'),
            value: (prop) => {
                return(<Checkbox checked={prop.delete} onChange={(e) => { handleChangeDelete(e, prop) }} size="small" className="py-0"/>
            )}
        },
    ];

    return (
        <ModalU show={props.modalShow} size={'lg'} title={`${t('ROLE')} [${t('EDIT')}]`}
            actionbtntext={t('UPDATE')}
            actionbtnclassname={"bg-green-700 font-14 medium text-white"}
            onSubmit={handleSubmit} onHide={props.setModalShow}>
                
            <form onSubmit={handleSubmit}>
                <div className="d-flex flex-row align-items-start mb-2">
                    <InputLabeled label={t('NAME')} type={'text'} value={name} setValue={setName} hint={t('NAME')} required={true}/>
                    <InputLabeled label={t('CODE')} type={'text'} value={code} setValue={setCode} hint={t('CODE')} required={true} className="ms-3"/>
                </div>
                {permissions && <TableStd modelProps={modelProps} data={permissions}/>}
            </form>
        </ModalU>
    );
}

export default Update